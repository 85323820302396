<template>
  <div>
    <div class="select">
      <div class="form-group">
        <div
          class="form-control form-control-sm select-open"
          :class="invalidClass"
          @click="showResult"
        >
          {{ selectedInstr }}
        </div>
      </div>
      <div v-if="selectResultVisible" class="form-group">
        <input
          class="form-control form-control-sm shadow-none"
          type="text"
          placeholder="поиск"
          v-model="input"
          @input="inputChange"
          v-focus
        />
        <div class="select-result">
          <div
            v-for="items in filterInstrument"
            :key="items.cod"
            class="form-control form-control-sm select-result-item"
            @click="selectInstr(items.name_instr, items.cod)"
          >
            {{ items.name_instr }}
          </div>
        </div>
      </div>
      <div v-else />
      <div class="form-group"></div>
    </div>
    <Loader v-if="loader" />
    <div v-else />
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
import Loader from "@/components/Loader.vue";
export default {
  name: "selected",
  props: ["invalidClass", "title", "setChangeInstr", "open"],
  components: {
    Loader
  },
  data: () => ({
    selectResultVisible: false,
    instrumentList: [],
    filterInstrument: [],
    input: "",
    selectedInstr: null,
    focused: false,
    loader: false,
    changeInstr: false
  }),
  methods: {
    showResult() {
      this.changeInstr
        ? (this.selectResultVisible = !this.selectResultVisible)
        : null;
    },
    inputChange() {
      if (this.input.length > 0) {
        this.filterInstrument = this.instrumentList.filter(item =>
          item.name_instr.toLowerCase().includes(this.input.toLowerCase())
        );
      }
    },
    selectInstr(name, cod) {
      this.selectedInstr = name;
      this.selectResultVisible = false;
      this.$emit("updateCodInstr", cod);
    }
  },
  mounted() {
    this.loader = true;
    HTTPGET("get_data/get_instr.php")
      .then(
        response =>
          (this.instrumentList = this.filterInstrument = response.data)
      )
      .then(() => (this.loader = false));
    this.selectedInstr = this.title;
    this.changeInstr = this.setChangeInstr;
    this.open ? (this.selectResultVisible = true) : null;
  }
};
</script>
<style lang="scss" scoped>
.select {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-top: 10px;
  &-open {
    display: table;
    cursor: pointer;
    border: none;
    border-radius: 0;
  }
  &-result {
    max-height: 250px;
    overflow: auto;
  }
  &-result-item {
    display: table;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #ced4da;
    cursor: pointer;
  }
}
.form-group {
  margin-bottom: 0;
}
input {
  border-radius: 0;
}
.container {
  margin: 0;
  margin-top: 10px;
  padding: 0;
}
.is-invalid-custom {
  border: 1px solid #dc3545;
}
</style>
