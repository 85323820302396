<template>
  <div class="modal-message">
    <div class="message-content">
      {{ message }}
      <div>
        <button @click="hide" class="btn btn-success btn-sm">понятно</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModalMessage",
  props: ["message"],
  methods: {
    hide() {
      this.$emit("hideMessage");
    }
  }
};
</script>
<style lang="scss" scoped>
.modal-message {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba($color: white, $alpha: 0.5);
}
.message-content {
  text-align: center;
  padding: 20px;
  background: #0062cc;
  color: white;
  max-width: 300px;
  border-radius: 3px;
}
button {
  margin-top: 20px;
}
</style>
