<template>
  <div class="uploadfile">
    <input
      type="file"
      required
      multiple
      class="custom-file-input uploadfile-input input-form"
      :class="errorClass"
      :id="setId"
      ref="labelFoto"
      @input="handleInput"
    />
    <label
      class="custom-file-label uploadfile-label shadow-none text-center"
      :for="setId"
      >{{ title }}</label
    >
    <div v-show="showPreview" class="image-preview">
      <div v-for="(item, idx) in file" :key="idx" class="image-preview-item">
        <div>{{ item.name }}</div>
        <div>{{ sizeFormat(item.size) }}KB</div>
        <div>
          <img v-bind:src="item.imagePreview" />
        </div>
        <div class="delete-foto-btn-wrapper">
          <button
            @click.prevent="deleteFoto(idx)"
            class="btn btn-outline-danger btn-sm delete-foto-btn"
          >
            удалить
          </button>
        </div>
      </div>
    </div>
    <Loader v-if="loader" />
    <div v-else />
    <ModalMessage
      v-if="messageVisible"
      :message="message + fileName"
      @hideMessage="hideMessage"
    />
    <div v-else />
  </div>
</template>
<script>
import Loader from "@/components/Loader.vue";
import ModalMessage from "@/components/ModalMessage.vue";
export default {
  name: "uploadFile",
  props: ["title", "setId", "errorClass", "typeFile"],
  data: () => ({
    formData: null,
    file: [],
    showPreview: false,
    imagePreview: [],
    loader: false,
    messageVisible: false,
    message: "недопустимое разрешение фала!",
    fileName: ""
  }),
  components: {
    Loader,
    ModalMessage
  },
  methods: {
    handleInput() {
      this.loader = true;
      this.showPreview = false;
      for (let i = 0; i < this.$refs.labelFoto.files.length; i++) {
        if (this.$refs.labelFoto.files[i]) {
          if (this.typeFile.test(this.$refs.labelFoto.files[i].name)) {
            this.file.push(this.$refs.labelFoto.files[i]);
          } else {
            this.fileName =
              this.fileName + " " + this.$refs.labelFoto.files[i].name;
            setTimeout(() => {
              this.deleteFoto(i);
            }, 1000);
          }
        }
      }
      if (this.file) {
        this.file.map(item => {
          let reader = new FileReader();
          reader.addEventListener(
            "load",
            function() {
              item.imagePreview = reader.result;
            }.bind(this)
          );
          reader.readAsDataURL(item);
        });
        setTimeout(() => {
          this.showPreview = true;
          this.$emit("updateFormDataFoto", this.file);
          this.loader = false;
          if (this.fileName.length) {
            this.messageVisible = true;
          }
        }, 1000);
      }
    },
    sizeFormat(size) {
      if (size) {
        return (size = size / 1024).toFixed(0);
      }
    },
    deleteFoto(idx) {
      this.file.splice(idx, 1);
      this.$emit("updateFormDataFoto", this.file);
      if (this.file.length === 0) {
        document.getElementById(this.setId).value = "";
      }
    },
    hideMessage() {
      this.messageVisible = false;
      this.fileName = "";
    }
  }
};
</script>
<style lang="scss" scoped>
label,
input {
  cursor: pointer;
  color: #495057;
}
img {
  height: 130px;
}
.image-preview {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 0.5rem;
  overflow: auto;
  margin-top: 10px;
}
.image-preview-item {
  padding: 3px;
  margin-right: 3px;
  border: 1px solid lightgray;
  text-align: center;
}
.delete-foto-btn-wrapper {
  border-top: 1px solid lightgray;
  padding: 3px;
  margin-top: 3px;
}
.delete-foto-btn {
  font-size: 0.75rem;
}
.custom-file-label::after {
  top: -100%;
}
</style>
